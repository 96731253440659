import React, { createElement, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { MenuItemLink, getResources } from 'react-admin';
import { Icon } from '@material-ui/core';
import { Settings, Person, LocationCity, AttachMoney, Business, Accessibility, AssignmentInd, History, Announcement } from '@material-ui/icons';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import ExploreIcon from '@material-ui/icons/Explore';
import EuroSymbol from '@material-ui/icons/EuroSymbol';
import Highlight from '@material-ui/icons/Highlight';
import ImportExport from '@material-ui/icons/ImportExport';
import Receipt from '@material-ui/icons/Receipt';

import useAccessRole from '../../hooks/useAccessRole';

import Images from '../../Assets/images';

import styles from './styles';

const Menu = (props) => {
	const { location: { pathname }, onMenuClick } = props;
	const { isAccess } = useAccessRole();
	const [open, setOpen] = useState(() => {
		if (
			pathname === '/roles' ||
			pathname === '/administrators' ||
			pathname === '/history' ||
			pathname === '/login_history' ||
			pathname === '/geolocations' ||
			pathname === '/marketing_message' ||
			pathname === '/exported_files'
		)
			return true;
		else
			return false;
	});
	const [openBinance, setOpenBinance] = useState(() => {
		if (pathname === '/binance_networks' || pathname === '/crypto_wallets' || pathname === '/crypto_payout_providers')
			return true;
		else
			return false;
	});

	const handleClick = () => {
		setOpen(!open);
	};

	const handleClickBinance = () => {
		setOpenBinance(!openBinance);
	};

	return (
		<div>
			<div style={styles.container}>
				<MenuItemLink
					to="/"
					primaryText="Dashboard"
					style={pathname === '/' ? { backgroundColor: '#00a6ff' } : {}}
					leftIcon={
						<Icon>
							<img src={Images.VitaLogo} height={15} width={15} style={{ paddingRight: 10, paddingLeft: 3 }} />
						</Icon>
					}
					onClick={onMenuClick}
				/>
				{
					isAccess('show_config') ?
						<MenuItemLink
							to={'/configuraciones'}
							style={pathname === '/configuraciones' ? { backgroundColor: '#00a6ff' } : {}}
							primaryText={'Configuraciones'}
							leftIcon={createElement(Settings, { style: styles.iconWhite })}
							onClick={onMenuClick}
						/>
						: null
				}
				{
					isAccess('show_general_limits') ?
						<MenuItemLink
							to={'/límites'}
							style={pathname === '/límites' ? { backgroundColor: '#00a6ff' } : {}}
							primaryText={'límites'}
							leftIcon={createElement(Settings, { style: styles.iconWhite })}
							onClick={onMenuClick}
						/>
						: null
				}
				{
					isAccess('show_countries') ?
						<MenuItemLink
							to={'/países'}
							style={pathname === '/países' ? { backgroundColor: '#00a6ff' } : {}}
							primaryText={'Países'}
							leftIcon={createElement(LocationCity, { style: styles.iconWhite })}
							onClick={onMenuClick}
						/>
						: null
				}
				{
					isAccess('show_prices_configuration') ?
						<MenuItemLink
							to={'/prices'}
							style={pathname === '/prices' ? { backgroundColor: '#00a6ff' } : {}}
							primaryText={'Precios'}
							leftIcon={createElement(Settings, { style: styles.iconWhite })}
							onClick={onMenuClick}
						/>
						: null
				}
				{
					isAccess('show_prices_configuration') ?
						<MenuItemLink
							to={'/vita_prices'}
							style={pathname === '/vita_prices' ? { backgroundColor: '#00a6ff' } : {}}
							primaryText={'Precios de Vita'}
							leftIcon={createElement(Settings, { style: styles.iconWhite })}
							onClick={onMenuClick}
						/>
						: null
				}
				{
					isAccess('show_settings_headers') ?
						<MenuItemLink
							to={'/settingsHeaders'}
							style={pathname === '/settingsHeaders' ? styles.noHiddenWordsSelect : styles.noHiddenWordsUnSelect}
							primaryText={'Cabeceras de configuración'}
							leftIcon={createElement(Settings, { style: styles.iconWhite })}
							onClick={onMenuClick}
						/>
						: null
				}
				{
					isAccess('transfer_rules') ?
						<MenuItemLink
							to={'/transferRules'}
							style={pathname === '/transferRules' ? { backgroundColor: '#00a6ff' } : {}}
							primaryText={'Reglas de transferencias'}
							leftIcon={createElement(Settings, { style: styles.iconWhite })}
							onClick={onMenuClick}
						/>
						: null
				}
				{
					isAccess('admin_system') ?
						<ListItem button onClick={handleClick} style={styles.collapseItem}>
							<ListItemIcon style={styles.iconCollapseItemm}>
								<SupervisorAccountIcon />
							</ListItemIcon>
							<ListItemText inset primary="Administrar" style={{ 'paddingLeft': 0 }} />
							{open ? <ExpandLess /> : <ExpandMore />}
						</ListItem>
						: null
				}
				<Collapse in={open} timeout="auto" unmountOnExit style={{ paddingLeft: 8 }}>
					{
						isAccess('show_roles') ?
							<MenuItemLink
								to={'/roles'}
								style={pathname === '/roles' ? { backgroundColor: '#00a6ff' } : {}}
								primaryText={'Roles'}
								leftIcon={createElement(Accessibility, { style: styles.iconWhite })}
								onClick={onMenuClick}
							/>
							: null
					}
					{
						isAccess('show_admin') ?
							<MenuItemLink
								to={'/administrators'}
								style={pathname === '/administrators' ? { backgroundColor: '#00a6ff' } : {}}
								primaryText={'Administradores'}
								leftIcon={createElement(AssignmentInd, { style: styles.iconWhite })}
								onClick={onMenuClick}
							/>
							: null
					}
					{
						isAccess('show_history') ?
							<MenuItemLink
								to={'/history'}
								style={pathname === '/history' ? { backgroundColor: '#00a6ff' } : {}}
								primaryText={'Historial de acciones'}
								leftIcon={createElement(History, { style: styles.iconWhite })}
								onClick={onMenuClick}
							/>
							: null
					}
					{/* {
						isAccess('show_history_login') ?
							<MenuItemLink
								to={'/login_history'}
								style={pathname === '/login_history' ? { backgroundColor: '#00a6ff' } : {}}
								primaryText={'Historial de Sesión'}
								leftIcon={createElement(UpdateIcon, { style: styles.iconWhite })}
								onClick={onMenuClick}
							/>
							: null
					} */}
					{
						isAccess('show_geolocations') ?
							<MenuItemLink
								to={'/geolocations'}
								style={pathname === '/geolocations' ? { backgroundColor: '#00a6ff' } : {}}
								primaryText={'Geolocalizaciones'}
								leftIcon={createElement(ExploreIcon, { style: styles.iconWhite })}
								onClick={onMenuClick}
							/>
							: null
					}
					{
						isAccess('show_prices_providers') ?
							<MenuItemLink
								to={'/prices_providers'}
								style={pathname === '/prices_providers' ? { backgroundColor: '#00a6ff' } : {}}
								primaryText={'Proveedores de precios'}
								leftIcon={createElement(EuroSymbol, { style: styles.iconWhite })}
								onClick={onMenuClick}
							/>
							: null
					}
					{
						isAccess('show_marketing_messages') ?
							<MenuItemLink
								to={'/marketing_message'}
								style={pathname === '/marketing_message' ? { backgroundColor: '#00a6ff' } : {}}
								primaryText={'Mensajes Marketing'}
								leftIcon={createElement(Highlight, { style: styles.iconWhite })}
								onClick={onMenuClick}
							/>
							: null
					}
					{
						isAccess('show_export_files') ?
							<MenuItemLink
								to={'/exported_files'}
								style={pathname === '/exported_files' ? { backgroundColor: '#00a6ff' } : {}}
								primaryText={'Archivos exportados'}
								leftIcon={createElement(Receipt, { style: styles.iconWhite })}
								onClick={onMenuClick}
							/>
							: null
					}
					{
						isAccess('show_banner') ?
							<MenuItemLink
								to={'/banners'}
								style={pathname === '/banners' ? { backgroundColor: '#00a6ff' } : {}}
								primaryText={'Comunicaciones'}
								leftIcon={createElement(ImportExport, { style: styles.iconWhite })}
								onClick={onMenuClick}
							/>
							: null
					}
					{
						isAccess('show_currencies') ? (
							<MenuItemLink
								to={'/currencies'}
								style={pathname === '/currencies' ? { backgroundColor: '#00a6ff' } : {}}
								primaryText={'Monedas'}
								leftIcon={createElement(AttachMoney, { style: styles.iconWhite })}
								onClick={onMenuClick}
							/>
						)
							: null
					}
					{
						isAccess('show_maintenance_charge') ?
							<MenuItemLink
								to={'/maintenance_charges'}
								style={pathname === '/maintenance_charges' ? { backgroundColor: '#00a6ff' } : {}}
								primaryText={'Cuota de mantenimiento'}
								leftIcon={createElement(AssignmentInd, { style: styles.iconWhite })}
								onClick={onMenuClick}
							/>
							: null
					}
					{
						isAccess('manage_pix_keys') ?
							<MenuItemLink
								to={'/pix'}
								style={pathname === '/pix' ? { backgroundColor: '#00a6ff' } : {}}
								primaryText={'Pix'}
								leftIcon={createElement(AssignmentInd, { style: styles.iconWhite })}
								onClick={onMenuClick}
							/>
							: null
					}
				</Collapse>
				{
					isAccess('admin_system') ?
						<ListItem button onClick={handleClickBinance} style={styles.collapseItem}>
							<ListItemIcon style={styles.iconCollapseItemm}>
								<SupervisorAccountIcon />
							</ListItemIcon>
							<ListItemText inset primary="Administrar crypto" style={{ 'paddingLeft': 0 }} />
							{openBinance ? <ExpandLess /> : <ExpandMore />}
						</ListItem>
						: null
				}
				<Collapse in={openBinance} timeout="auto" unmountOnExit style={{ paddingLeft: 8 }}>
					{
						isAccess('show_crypto_networks') ?
							<MenuItemLink
								to={'/binance_networks'}
								style={pathname === '/binance_networks' ? { backgroundColor: '#00a6ff' } : {}}
								primaryText={'Blockchains'}
								leftIcon={createElement(Accessibility, { style: styles.iconWhite })}
								onClick={onMenuClick}
							/>
							: null
					}
					{
						isAccess('show_crypto_addresses') ?
							<MenuItemLink
								to={'/custody_error_crypto_wallets'}
								style={pathname === '/custody_error_crypto_wallets' ? { backgroundColor: '#00a6ff' } : {}}
								primaryText={'Transferencia a custodia'}
								leftIcon={createElement(Accessibility, { style: styles.iconWhite })}
								onClick={onMenuClick}
							/>
							: null
					}
					{
						isAccess('show_crypto_payout_providers') ?
							<MenuItemLink
								to={'/crypto_payout_providers'}
								style={pathname === '/crypto_payout_providers' ? { backgroundColor: '#00a6ff' } : {}}
								primaryText={'Proveedores de pagos'}
								leftIcon={createElement(Accessibility, { style: styles.iconWhite })}
								onClick={onMenuClick}
							/>
							: null
					}
				</Collapse>
			</div>
			<div style={styles.containerDark}>
				{
					isAccess('show_users') ?
						<MenuItemLink
							to={'/usuarios'}
							style={pathname === '/usuarios' ? { backgroundColor: '#00a6ff' } : {}}
							primaryText={'Usuarios'}
							leftIcon={createElement(Person, { style: styles.iconWhite })}
							onClick={onMenuClick}
						/>
						: null
				}
				{
					isAccess('show_beneficiaries') ?
						<MenuItemLink
							to={'/beneficiaries'}
							style={pathname === '/beneficiaries' ? { backgroundColor: '#00a6ff' } : {}}
							primaryText={'Destinatarios'}
							leftIcon={createElement(Person, { style: styles.iconWhite })}
							onClick={onMenuClick}
						/>
						: null
				}
				{
					isAccess('show_card_accounts') ?
						<MenuItemLink
							to={'/card_accounts'}
							style={pathname === '/card_accounts' ? { backgroundColor: '#00a6ff' } : {}}
							primaryText={'Cuentas Vita Cards'}
							leftIcon={createElement(Person, { style: styles.iconWhite })}
							onClick={onMenuClick}
						/>
						: null
				}
				{
					isAccess('show_preferential_prices') ?
						<MenuItemLink
							to={'/preferential_prices'}
							style={pathname === '/preferential_prices' ? { backgroundColor: '#00a6ff' } : {}}
							primaryText={'Precios preferenciales'}
							leftIcon={createElement(AttachMoney, { style: styles.iconWhite })}
							onClick={onMenuClick}
						/>
						: null
				}
				{
					isAccess('show_business') ?
						<MenuItemLink
							to={'/negocios'}
							style={pathname === '/negocios' ? { backgroundColor: '#00a6ff' } : {}}
							primaryText={'Negocios'}
							leftIcon={createElement(Business, { style: styles.iconWhite })}
							onClick={onMenuClick}
						/>
						: null
				}
			</div>
			<div style={styles.container}>
				{
					isAccess('show_requests') ?
						<MenuItemLink
							to={'/solicitudes'}
							style={pathname === '/solicitudes' ? { backgroundColor: '#00a6ff' } : {}}
							primaryText={'Solicitudes'}
							leftIcon={createElement(History, { style: styles.iconWhite })}
							onClick={onMenuClick}
						/>
						: null
				}
				{
					isAccess('show_general_alerts') ?
						<MenuItemLink
							to={'/general_alerts'}
							style={pathname === '/general_alerts' ? { backgroundColor: '#00a6ff' } : {}}
							primaryText={'Alertas'}
							leftIcon={createElement(Announcement, { style: styles.iconWhite })}
							onClick={onMenuClick}
						/>
						: null
				}
				{
					isAccess('show_transactions') ?
						<MenuItemLink
							to={'/transacciones'}
							style={pathname === '/transacciones' ? { backgroundColor: '#00a6ff' } : {}}
							primaryText={'Transacciones'}
							leftIcon={createElement(AttachMoney, { style: styles.iconWhite })}
							onClick={onMenuClick}
						/>
						: null
				}
				{
					isAccess('show_transactions_profitability') ?
						<MenuItemLink
							to={'/transactions_profitability'}
							style={pathname === '/transactions_profitability' ? styles.noHiddenWordsSelect : styles.noHiddenWordsUnSelect}
							primaryText={'Rentabilidad de Transacciones'}
							leftIcon={createElement(AttachMoney, { style: styles.iconWhite })}
							onClick={onMenuClick}
						/>
						: null
				}
				{
					isAccess('show_transactions_status') ?
						<MenuItemLink
							to={'/estados de las transacciones'}
							style={pathname === '/estados de las transacciones' ? styles.noHiddenWordsSelect : styles.noHiddenWordsUnSelect}
							primaryText={'Estados de las transacciones'}
							leftIcon={createElement(AttachMoney, { style: styles.iconWhite })}
							onClick={onMenuClick}
						/>
						: null
				}
				{
					isAccess('show_crypto_withdrawal') ? <MenuItemLink
						to={'/cryptos'}
						style={pathname === '/cryptos' ? { backgroundColor: '#00a6ff' } : {}}
						primaryText={'Retiros crypto'}
						leftIcon={createElement(AttachMoney, { style: styles.iconWhite })}
						onClick={onMenuClick}
					/>
						: null
				}
				{
					isAccess('batch_reload') ?
						<MenuItemLink
							to={'/lotes'}
							style={pathname === '/lotes' ? { backgroundColor: '#00a6ff' } : {}}
							primaryText={'Recargas por lotes'}
							leftIcon={createElement(AttachMoney, { style: styles.iconWhite })}
							onClick={onMenuClick}
						/>
						: null
				}
				{
					isAccess('batch_payout') ?
						<MenuItemLink
							to={'/batch_payout'}
							style={pathname === '/batch_payout' ? { backgroundColor: '#00a6ff' } : {}}
							primaryText={'Pagos por lotes'}
							leftIcon={createElement(AttachMoney, { style: styles.iconWhite })}
							onClick={onMenuClick}
						/>
						: null
				}
				{
					isAccess('show_withdrawal') ?
						<MenuItemLink
							to={'/retiros'}
							style={pathname === '/retiros' ? { backgroundColor: '#00a6ff' } : {}}
							primaryText={'Retiros'}
							leftIcon={createElement(AttachMoney, { style: styles.iconWhite })}
							onClick={onMenuClick}
						/>
						: null
				}
				{
					isAccess('show_withdraw_btc') ?
						<MenuItemLink
							to={'/bitcoin_withdrawal'}
							style={pathname === '/bitcoin_withdrawal' ? { backgroundColor: '#00a6ff' } : {}}
							primaryText={'Retirar Crypto'}
							leftIcon={createElement(AttachMoney, { style: styles.iconWhite })}
							onClick={onMenuClick}
						/>
						: null
				}
				{
					isAccess('show_bank_account_accesses') ?
						<MenuItemLink
							to={'/bank_account_accesses'}
							style={pathname === '/bank_account_accesses' ? styles.noHiddenWordsSelect : styles.noHiddenWordsUnSelect}
							primaryText={'Accesos a cuentas bancarias'}
							leftIcon={createElement(AttachMoney, { style: styles.iconWhite })}
							onClick={onMenuClick}
						/>
						: null
				}
				{
					isAccess('show_bank_movements') ?
						<MenuItemLink
							to={'/bank_movements'}
							style={pathname === '/bank_movements' ? { backgroundColor: '#00a6ff' } : {}}
							primaryText={'Movimientos bancarios'}
							leftIcon={createElement(AttachMoney, { style: styles.iconWhite })}
							onClick={onMenuClick}
						/>
						: null
				}
				{
					isAccess("show_segments") ? (
						<MenuItemLink
							to={"/segments"}
							style={
								pathname === "/segments" ? { backgroundColor: "#00a6ff" } : {}
							}
							primaryText={"Segmentos"}
							leftIcon={createElement(AttachMoney, { style: styles.iconWhite })}
							onClick={onMenuClick}
						/>)
						: null
				}
				{
					isAccess("show_coupons") ? (
						<MenuItemLink
							to={"/coupons"}
							style={
								pathname === "/coupons" ? { backgroundColor: "#00a6ff" } : {}
							}
							primaryText={"Cupones"}
							leftIcon={createElement(AttachMoney, { style: styles.iconWhite })}
							onClick={onMenuClick}
						/>)
						: null
				}
				{isAccess("show_referrer") ? (
					<MenuItemLink
						to={"/referrers"}
						style={
							pathname === "/referrers" ? { backgroundColor: "#00a6ff" } : {}
						}
						primaryText={"Top Referidos"}
						leftIcon={createElement(Person, { style: styles.iconWhite })}
						onClick={onMenuClick}
					/>
				) : null}
			</div>
			<div style={styles.containerDark}>
				{
					isAccess('show_dlocal') ?
						<MenuItemLink
							to={'/dlocal'}
							style={pathname === '/dlocal' ? { backgroundColor: '#00a6ff' } : {}}
							primaryText={'Dlocal'}
							leftIcon={
								<Icon>
									<img src={Images.DLocalLogoSvg} height={15} width={15} style={{ paddingRight: 10, paddingLeft: 3 }} />
								</Icon>
							}
							onClick={onMenuClick}
						/>
						: null
				}
				{
					isAccess('get_custodial_accounts_fortress') &&
					<MenuItemLink
						to={'/fortress'}
						style={pathname === '/fortress' ? { backgroundColor: '#00a6ff' } : {}}
						primaryText={'Fortress'}
						leftIcon={createElement(Person, { style: styles.iconWhite })}
						onClick={onMenuClick}
					/>
				}
			</div>
		</div>
	);
};

const mapStateToProps = state => ({
	resources: getResources(state),
});

export default withRouter(connect(mapStateToProps)(Menu));

import { apiCall } from "../../Middlewares";
import { ENDPOINTS } from "../../Config/env";

const getVitaUser = async (query) =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.USERS.USERS_LIST(query),
  });

const acceptedUsersVerification = async (id) =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.USERS.ACCEPTED_VERIFICATION(id),
  });

const rejectedUsersVerification = async (id, rejected_note) => {
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.USERS.REJECTED_VERIFICATION(id),
    data: { rejected_note }
  });
}

const requestBiometricUsersVerification = async (id) =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.USERS.REQUEST_BIOMETRIC_VERIFICATION(id),
  });

const acceptBiometricUsersVerification = async (id) =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.USERS.ACCEPT_BIOMETRIC_VERIFICATION(id),
  });

const rejectBiometricUsersVerification = async (id) =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.USERS.REJECT_BIOMETRIC_VERIFICATION(id),
  });

const resetBiometricUsersVerification = async (id) =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.USERS.RESET_BIOMETRIC_VERIFICATION(id),
  });

const reloadBiometricVerification = async (id) =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.USERS.RELOAD_BIOMETRIC_VERIFICATION(id),
  })

const resetUsersVerification = async (id) =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.USERS.RESET_VERIFICATION(id),
  });

const resetUserSession = async (id) =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.USERS.RESET_SESSION(id),
  });

const getProfileAdmin = async () =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.USERS.PROFILE,
  });

const getBalancesGlobalAdmin = async () =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.USERS.BALANCE,
  });

const getPendingTransactionsBalance = async () =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.USERS.PENDING_TRANSACTIONS_BALANCE,
  });

const getGeneralBalance = async () =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.USERS.GENERAL_BALANCE,
  });

const getBalancePerCountry = async (iso_code) =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.USERS.BALANCE_PER_COUNTRY(iso_code)
  })

const confirmDoubleAuth = async (code, headers) =>
  await apiCall(
    {
      authorization: true,
      ...ENDPOINTS.ADMIN.AUTH.CONFIRM_SECOND_FACTOR,
      data: { code },
    },
    headers
  );

const requestCriminalVerification = async (id, provider) => {
  return await apiCall(
    {
      authorization: true,
      ...ENDPOINTS.ADMIN.USERS.REQUEST_CRIMINAL_VERIFICATION(id, provider),
    }
  )
}

const updateCriminalVerificationItemIgnoreStatus = async (user_id, neitcom_result_id, payload) => {
  return await apiCall(
    {
      authorization: true,
      ...ENDPOINTS.ADMIN.USERS.UPDATE_CRIMINAL_VERIFICATION_ITEM_IGNORE_STATUS(user_id, neitcom_result_id),
      data: payload
    },
  )
}

const unbannedUser = async (id) =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.USERS.UNBANNED_USER(id),
  });

const bannedUser = async (id) =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.USERS.BANNED_USER(id),
  });

const addUserLimit = async (data) =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.USERS.ADD_USER_LIMIT,
    data,
  });

const getUserLimit = async (user_id) => {
  const url = ENDPOINTS.ADMIN.USERS.GET_USER_LIMIT.url;
  return await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.USERS.GET_USER_LIMIT,
    url:
      user_id !== null
        ? url.replace(":user_id", user_id)
        : url.replace("?q[user_id_eq]=:user_id", "?q[user_id_null]=1"),
  });
};

const deleteUserLimit = async (id) => {
  const url = ENDPOINTS.ADMIN.USERS.DELETE_USER_LIMIT.url;
  return await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.USERS.DELETE_USER_LIMIT,
    url: url.replace(":id", id),
  });
};

const fetchStatusLogs = async (id) => {
  return await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.USERS.USER_STATUS_LOGS(id),
  });
};

const createSendUserRoute = async (data) =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.USERS.CREATE_SEND_USER_ROUTE,
    data,
  });

const getCurrentSendUserRoutes = async (id) =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.USERS.GET_SEND_USER_ROUTES(id),
  });

const getAvailableSendRoutes = async (id) =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.USERS.GET_AVAILABLE_SEND_ROUTES(id),
  });

const saveEditUserRoute = async (data) =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.USERS.SAVE_EDIT_USER_ROUTE,
    data,
  });

const deleteUserRoutes = async (data) =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.USERS.DELETE_USER_ROUTE,
    data,
  });

const preferentialAutomaticPrices = async (data) =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.USERS.PREFERENTIAL_AUTOMATIC_PRICES,
    data,
  });

const getUserQuotationPrices = async (user_id) => {
  return await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.USERS.USER_QUOTATION_PRICES(user_id)
  });
};

const getUserCryptoQuotationPrices = async (user_id) => {
  return await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.USERS.USER_CRYPTO_QUOTATION_PRICES(user_id)
  });
};

const getPendingTransactionsList = async () =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.USERS.PENDING_TRANSACTIONS_LIST,
  });

const exportPendingTransactionsList = async () => {
  let query = `?export=true`;

  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.USERS.OLD_PENDING_TRANSACTIONS_LIST(query),
  });
};

const getOldPendingTransactionsList = async (total, iso_code, blocked) => {
  let query = `?total=${total}`;
  if (iso_code) {
    query += `&iso_code=${iso_code}`;
  }

  if (blocked) {
    query += `&blocked=${blocked}`;
  }

  return await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.USERS.OLD_PENDING_TRANSACTIONS_LIST(query),
  });
}

const validateCurrency = async (email, currency) =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.USERS.VALIDATE_CURRENCY(email, currency),
  });

const listAttachmentFiles = async (resource_id) =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.ATTACHMENT_FILES.ATTACHMENT_FILES_LIST('users', resource_id),
  });

const createAttachmentFile = async (resource_id, data) =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.ATTACHMENT_FILES.ATTACHMENT_FILES_CREATE('users', resource_id),
    data,
  })

const updateAttachmentFile = async (resource_id, element_id, data) =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.ATTACHMENT_FILES.ATTACHMENT_FILES_EDIT('users', resource_id, element_id),
    data
  })

const deleteAttachmentFile = async (resource_id, element_id) =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.ATTACHMENT_FILES.ATTACHMENT_FILES_DELETE('users', resource_id, element_id)
  })

const requestUpdateProfile = async (id, data) =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.USERS.REQUEST_UPDATE_PROFILE(id),
    data,
  });

const validateEmail = async (user_id, email) =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.USERS.VALIDATE_EMAIL(user_id, email),
  });

const updateEmail = async (user_id, email) =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.USERS.UPDATE_EMAIL(user_id, email),
  });

const updateCategory = async (user_id, category) =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.USERS.UPDATE_CATEGORY(user_id, category),
  });

const updateBusiness = async (user_id, data) =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.USERS.UPDATE_BUSINESS(user_id),
    data
  });

const getTrxLimits = async (userId, query) => {
  const url = `${ENDPOINTS.ADMIN.USERS.GET_TRX_LIMITS.url.replace(':id', userId)}${query}`;
  return await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.USERS.GET_TRX_LIMITS,
    url
  });
};

const suppressUser = async (data) =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.USERS.SUPPRESS_USER,
    data
  });

const getInfoPaymentLinkUserBusiness = async (userId) =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.USERS.GET_INFO_PAYMENT_LINK_USER_BUSINESS(userId),
  });

const updateInfoPaymentLinkUserBusiness = async (userId, data) =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.USERS.UPDATE_INFO_PAYMENT_LINK_USER_BUSINESS(userId),
    data,
  });

const assignProviderPaymentAvailableUserBusiness = async (userId, data) => {
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.USERS.ASIGN_PROVIDER_PAYMENT_AVAILABLE_USER_BUSINESS(userId),
    data,
  });
}

const updateProviderPaymentActiveUserBusiness = async (userId, providerId, data) =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.USERS.UPDATE_PROVIDER_PAYMENT_ACTIVE_USER_BUSINESS(userId, providerId),
    data,
  });

const deleteUserProviderPaymentActiveUserBusiness = async (userId, providerId) =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.USERS.DELETE_PROVIDER_PAYMENT_ACTIVE_USER_BUSINESS(userId, providerId),
  });

const getBusinessPaymentOrders = async (userId, count, page) =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.USERS.GET_BUSINESS_PAYMENT_ORDERS(userId, count, page),
  });

const getUserBalances = async (user_id) =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.USERS.GET_USER_BALANCES(user_id),
  });

const getAvailableCurrencies = async (user_id) =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.USERS.GET_AVAILABLE_CURRENCIES(user_id),
  });

const activateCurrencyToUser = async (id, data) =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.USERS.ACTIVATE_CURRENCY_TO_USER(id),
    data
  })

const assignCLABENumber = async (user_id) =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.USERS.ASSIGN_CLABE_NUMBER(user_id)
  });

const getBalancesFile = async () =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.USERS.GET_BALANCES_FILE,
  });

const getBalanceAdminEmail = async () =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.USERS.GET_BALANCE_ADMIN_EMAIL,
  });
const getInfoUsdAccount = async (user_id) =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.USERS.GET_INFO_USD_ACCOUNT(user_id),
  });

const rejectUsdAccount = async (user_id, data) =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.USERS.REJECT_USD_ACCOUNT(user_id),
    data
  });

const retryUsdAccountCreation = async (user_id) =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.USERS.RETRY_USD_ACCOUNT_CREATION(user_id),
  });

const manualUpdateUsdAccount = async (user_id, data) =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.USERS.MANUAL_UPDATE_USD_ACCOUNT(user_id),
    data
  });

const retryVirtualAccountCreation = async (user_id) =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.USERS.RETRY_VIRTUAL_ACCOUNT_CREATION(user_id),
  });

export default {
  addUserLimit,
  getUserLimit,
  deleteUserLimit,
  acceptedUsersVerification,
  rejectedUsersVerification,
  requestBiometricUsersVerification,
  acceptBiometricUsersVerification,
  rejectBiometricUsersVerification,
  resetBiometricUsersVerification,
  reloadBiometricVerification,
  resetUsersVerification,
  getProfileAdmin,
  getBalancesGlobalAdmin,
  getBalancePerCountry,
  confirmDoubleAuth,
  unbannedUser,
  bannedUser,
  getVitaUser,
  resetUserSession,
  fetchStatusLogs,
  getGeneralBalance,
  getPendingTransactionsBalance,
  requestCriminalVerification,
  createSendUserRoute,
  getCurrentSendUserRoutes,
  getAvailableSendRoutes,
  saveEditUserRoute,
  deleteUserRoutes,
  preferentialAutomaticPrices,
  updateCriminalVerificationItemIgnoreStatus,
  getUserQuotationPrices,
  getPendingTransactionsList,
  exportPendingTransactionsList,
  getOldPendingTransactionsList,
  validateCurrency,
  listAttachmentFiles,
  createAttachmentFile,
  updateAttachmentFile,
  deleteAttachmentFile,
  requestUpdateProfile,
  validateEmail,
  updateEmail,
  updateCategory,
  updateBusiness,
  suppressUser,
  getTrxLimits,
  getBalancesFile,
  updateProviderPaymentActiveUserBusiness,
  assignProviderPaymentAvailableUserBusiness,
  getInfoPaymentLinkUserBusiness,
  updateInfoPaymentLinkUserBusiness,
  deleteUserProviderPaymentActiveUserBusiness,
  getBusinessPaymentOrders,
  getUserBalances,
  getAvailableCurrencies,
  activateCurrencyToUser,
  getUserCryptoQuotationPrices,
  assignCLABENumber,
  getBalanceAdminEmail,
  getInfoUsdAccount,
  rejectUsdAccount,
  retryUsdAccountCreation,
  manualUpdateUsdAccount,
  retryVirtualAccountCreation,
}

import React from "react";
import { Modal, Card, Backdrop } from "@material-ui/core";
import PropTypes from "prop-types";

const ModalComponent = (props) => {
  const {
    open,
    onClose,
    maxWidth,
    marginLeft,
    marginRight,
    marginTop,
    backgroundColor,
    children,
    cardStyle,
    backdropStyle,
  } = props;

  return (
    <Modal
      open={open}
      onClose={onClose}
      BackdropComponent={(props) => (
        <Backdrop
          {...props}
          style={{
            backgroundColor: "transparent",
            ...backdropStyle
          }}
        />
      )}
    >
      <Card
        style={{
          marginLeft,
          marginRight,
          backgroundColor,
          marginTop,
          maxWidth,
          ...cardStyle,
        }}
      >
        {children}
      </Card>
    </Modal>
  );
};

ModalComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  maxWidth: PropTypes.number,
  marginLeft: PropTypes.string || PropTypes.number,
  marginRight: PropTypes.string || PropTypes.number,
  marginTop: PropTypes.string || PropTypes.number,
  backgroundColor: PropTypes.string,
  children: PropTypes.node.isRequired,
  cardStyle: PropTypes.shape({}),
  backdropStyle: PropTypes.shape({}),
};

ModalComponent.defaultProps = {
  maxWidth: 910,
  onClose: () => {},
  marginLeft: "auto",
  marginRight: "auto",
  marginTop: "auto",
  backgroundColor: "transparent",
  children: null,
  cardStyle: {},
  backdropStyle: {},
};

export default ModalComponent;
